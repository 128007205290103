import axiosIns from "@axios"
import { defineStore } from "pinia"

export const useSubscriptionStore = defineStore("Subscription", {
  state: () => ({
    data: {
      info: {},
      isSubscribed: false,
    },
  }),
  actions: {
    async isSubscribed() {
      try {
        const response = await axiosIns.get("/subscription/isSubscribed")
        this.data.isSubscribed = response.data.isSubscribed
        return response.data.isSubscribed
      } catch (error) {
        console.error("Error fetching subscription details:", error)
        return false
      }
    },

    async getInfo() {
      try {
        const response = await axiosIns.get("/subscription")
        this.data.info = response.data
        return response.data
      } catch (error) {
        console.error("Error fetching subscription info:", error)
        return null
      }
    },
  },
})
