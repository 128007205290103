/* eslint-disable import/order */
import "@/@iconify/icons-bundle"
import App from "@/App.vue"
import { ability } from "@/plugins/casl/ability"
import i18n from "@/plugins/i18n"
import layoutsPlugin from "@/plugins/layouts"
import vuetify from "@/plugins/vuetify"
import { loadFonts } from "@/plugins/webfontloader"
import router from "@/router"
import { abilitiesPlugin } from "@casl/vue"
import "@core/scss/template/index.scss"
import { createGtm } from "@gtm-support/vue-gtm"
import * as Sentry from "@sentry/vue"
import "@styles/styles.scss"
import "flag-icons/css/flag-icons.min.css"
import { createPinia } from "pinia"
import piniaPluginPersistedstate from "pinia-plugin-persistedstate"
import { createVPhoneInput } from "v-phone-input"
import "v-phone-input/dist/v-phone-input.css"
import { createApp } from "vue"
import vueCookies from "vue-cookies"
import { VAutocomplete } from "vuetify/components"
loadFonts()

// Create vue app
const app = createApp(App)

// 👉 - Setup GTM

app.use(
  createGtm({
    id: import.meta.env.VITE_GTM_ID || "__VITE_GTM_ID__",
    vueRouter: router,
  })
)

// 👉 - Setup pinia

const pinia = createPinia()

pinia.use(piniaPluginPersistedstate)

// 👉 - Setup sentry

Sentry.init({
  app,
  enabled:
    (import.meta.env.VITE_PRODUCTION_MODE || "__VITE_PRODUCTION_MODE__") ===
    "TRUE",
  dsn: import.meta.env.VITE_SENTRY_DSN || "__VITE_SENTRY_DSN__",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate:
    (import.meta.env.VITE_PRODUCTION_MODE || "__VITE_PRODUCTION_MODE__") ===
    "TRUE"
      ? 0.5
      : 1.0,

  tracePropagationTargets: [
    "localhost",
    import.meta.env.VITE_API_BASE_URL || "__VITE_API_BASE_URL__",
    import.meta.env.BASE_URL || "__BASE_URL__",
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysOnErrorSampleRate:
    (import.meta.env.VITE_PRODUCTION_MODE || "__VITE_PRODUCTION_MODE__") ===
    "TRUE"
      ? 0.5
      : 1.0,

  release: `domap-frontend@${APP_VERSION}`,

  environment:
    (import.meta.env.VITE_PRODUCTION_MODE || "__VITE_PRODUCTION_MODE__") ===
    "TRUE"
      ? "production"
      : "development",

  trackComponents: true,
  hooks: ["mount", "update", "unmount"],
})

// 👉 - add vuetify components

const vPhoneInput = createVPhoneInput({
  countryIconMode: "svg",
  enableSearchingCountry: true,
})

// 👉 - Use plugins
app.component("VAutocomplete", VAutocomplete)
app.use(vPhoneInput)
app.use(vuetify)
app.use(pinia)
app.use(router)
app.use(layoutsPlugin)
app.use(i18n)
app.use(vueCookies, { expires: "7d" })
app.use(abilitiesPlugin, ability, {
  useGlobalProperties: true,
})

// Mount vue app
app.mount("#app")
